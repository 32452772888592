const educationlist = [{
    img: require('../assets/integration/science/icon.png'),
    title: '多端互联:实体园+高校+专家智库,打破地域壁垒融合教学资源',
}, {
    img: require('../assets/integration/science/icon.png'),
    title: '即时深度互动:带来全实景的沉浸式、互动式教学体验',
}, {
    img: require('../assets/integration/science/icon.png'),
    title: '开放式平台:优质内容资源,构建幼教新生态',
}]

const portionheaderlist = [{
    img: require('../assets/integration/science/2.png'),
    title: '幼儿园管理系统',
    size: ['❉ 精细化管理流程 ', '❉ 优质园所教学资源库', ' ❉ 强大的模块化功能'],
    show:false
}, {
    img: require('../assets/integration/science/3.png'),
    title: '学生学习活动支持系统',
    size: ['❉ 学生高效自学平台', '❉ 丰富视频课程资源', '❉ 智能化学生档案管理', ' ❉ 实习实训跟踪反馈'],
    show:false

}, {
    img: require('../assets/integration/science/4.png'),
    title: '教师保教活动系统',
    size: ['❉ 优质资源助力高效备课', '❉ 轻松管理学生学习进度', '❉ 网上教研助力学术交流', ' ❉ 多元化活动组织和分享'],
    show:false


}]

const portionfooterlist = [{
    img: require('../assets/integration/science/5.png'),
    title: '教师培训成长发展系统',
    size: ['❉ 多元课程资源库', '❉ 培训活动丰富', '❉ 便捷场地预订和管理', ' ❉ 精准数据支持助力课程管理', ' ❉ 强大的学员管理功能'],
    show:false

}, {
    img: require('../assets/integration/science/6.png'),
    title: '幼儿园观摩互动系统',
    size: ['❉ 校园教育教学观摩互动', '❉ 观摩园所真实课堂', '❉ 精品观摩资源回放',],
    show:false
}]

const roomlist = [{
    img: require('../assets/integration/science/7.png'),
    title: '常态化智慧教室',
}, {
    img: require('../assets/integration/science/8.png'),
    title: '研讨型智慧教室',
}, {
    img: require('../assets/integration/science/9.png'),
    title: '精品智慧教室',
}]
const roomvideolist = [{
    img:require('../assets/integration/science/10.png'),
    title:'多屏体验',
    des:"支持各种终端的一致接入体验",
},{
    img:require('../assets/integration/science/11.png'),
    title:'视频点播、直播',
    des:"支持各种终端的视频直播,可分享至第三方社交平台。",
},{
    img:require('../assets/integration/science/12.png'),
    title:'资源管理',
    des:"视频、文档、图片进行管理，并最终形成知识点体系。",
},{
    img:require('../assets/integration/science/13.png'),
    title:'远程巡课督导',
    des:"实现教师巡视、课表管理和教师使用情况的统计分析。",
},{
    img:require('../assets/integration/science/14.png'),
    title:'智能考勤',
    des:"人脸抓拍,无感知的人脸识别自动点名。",
},{
    img:require('../assets/integration/science/15.png'),
    title:'设备集控',
    des:"对教室管理、远程控制教室设备、统计教室能耗、支持教室按能耗排名。",
}]

const baselist = [{
    img: require('../assets/integration/science/17.png'),
    title: '大学观摩实训',
}, {
    img: require('../assets/integration/science/18.png'),
    title: '线上观摩系统',
}, {
    img: require('../assets/integration/science/19.png'),
    title: '线下实习',
}]
const sciencejson = {
    educationlist,
    portionheaderlist,
    portionfooterlist,
    roomlist,
    roomvideolist,
    baselist

}
export default sciencejson;