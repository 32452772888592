<template>
    <div class="science">
        <img class="boximg" src="../../assets/integration/science/1.png" alt="" srcset="">
        <jobnav name="智慧教育系统" />
        <div class="education">
            <div class="educationdesone" v-for="(item,index) in educationlist" :key="index">
                <div class="educationicon">
                    <img :src="item.img" alt="">
                </div>
                <div class="educationdes">{{item.title}}</div>
            </div>
        </div>
        <div class="portion">
            <div class="portionheader">
                <div class="portionheaderone" @mouseenter="item.show=!item.show" @mouseleave="item.show=!item.show" v-for="(item,index) in portionheaderlist" :key="index">
                    <div class="portionheaderoneimg">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="portionheaderonetitle">
                        <div class="portionheaderoneicon">
                            <img src="../../assets/integration/science/icon1.png" alt="">
                        </div>
                        <div class="portionheaderonetxt">{{item.title}}</div>
                        <div class="portionheaderoneicon">
                            <img src="../../assets/integration/science/icon1.png" alt="">
                        </div>
                    </div>
                    <div class="modeclass" v-show="item.show">
                        <div class="modeicon">
                            <img src="../../assets/integration/science/icon2.png" alt="">
                        </div>
                        <div class="modecontent">
                            <div style="margin-bottom:4px" v-for="(items,indexs) in item.size" :key="indexs">{{items}}</div>
                        </div>
                        <div class="modeicon">
                            <img src="../../assets/integration/science/icon3.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="portionfooter">
                <div class="portionfooterone" @mouseenter="item.show=!item.show" @mouseleave="item.show=!item.show" v-for="(item,index) in portionfooterlist" :key="index">
                    <div class="portionfooteroneimg">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="portionfooteronetitle">
                        <div class="portionfooteroneicon">
                            <img src="../../assets/integration/science/icon1.png" alt="">
                        </div>
                        <div class="portionfooteronetxt">{{item.title}}</div>
                        <div class="portionfooteroneicon">
                            <img src="../../assets/integration/science/icon1.png" alt="">
                        </div>
                    </div>
                    <div class="modeclass" v-show="item.show">
                        <div class="modeicon">
                            <img src="../../assets/integration/science/icon2.png" alt="">
                        </div>
                        <div class="modecontent">
                            <div style="margin-bottom:4px" v-for="(items,indexs) in item.size" :key="indexs">{{items}}</div>
                        </div>
                        <div class="modeicon">
                            <img src="../../assets/integration/science/icon3.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <jobnav name="智慧教室" />
        <div class="room">
            <div class="roomone" v-for="(item,index) in roomlist" :key="index">
                <div class="roomoneimg">
                    <img :src="item.img" alt="">
                </div>
                <div class="roomonetitle">{{item.title}}</div>
            </div>
        </div>
        <div class="roomvideo">
            <div class="roomvideoone" v-for="(item,index) in roomvideolist" :key="index">
                <div class="roomvideotitle">{{item.title}}</div>
                <div class="roomvideodes">{{item.des}}</div>
                <div class="roomvideoimg">
                    <img :src="item.img" alt="">
                </div>

            </div>
        </div>
        <jobnav name="培训优势" />
        <div class="base">
            <img src="../../assets/integration/science/16.png" alt="">
        </div>
        <div class="basebox">
            <div class="baseone" v-for="(item,index) in baselist" :key="index">
                <div class="baseimg">
                    <img :src="item.img" alt="">
                </div>
                <div class="basetitle">{{item.title}}</div>
            </div>
        </div>
        <jobnav name="教室数字素养赋能" />
        <div class="neng">
            <div class="nengone">
                <img src="../../assets/integration/science/20.png" alt="">
            </div>
            <div class="nengone1">
                <img src="../../assets/integration/science/21.png" alt="">
            </div>
        </div>

    </div>
</template>
<script>
import jobnav from "../../components/jobnav.vue";
import sciencejson from "../../store/sciencejson";
export default {
    components: {
        jobnav,
    },
    data() {
        return {
            educationlist: [],
            portionheaderlist: [],
            portionfooterlist: [],
            roomlist: [],
            roomvideolist:[],
            baselist:[]
        };
    },
    created() {
        this.educationlist = sciencejson.educationlist;
        this.portionheaderlist = sciencejson.portionheaderlist;
        this.portionfooterlist = sciencejson.portionfooterlist;
        this.roomlist = sciencejson.roomlist;
        this.roomvideolist = sciencejson.roomvideolist;
        this.baselist = sciencejson.baselist;


    },
};
</script>
<style lang="scss" scoped>
img {
    width: 100%;
}
.science {
    .boximg {
        width: 100%;
        margin-bottom: 30px;
    }
    .education {
        width: 80vw;
        margin: 30px auto;
        .educationdesone {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .educationicon {
                width: 20px;
                margin-right: 10px;
                display: flex;
                img {
                    align-self: center;
                }
            }
            .educationdes {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    .portion {
        width: 80vw;
        margin: 0px auto 30px;
        .portionheader {
            width: 100%;
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: auto auto auto;
            grid-gap: 20px;
            .portionheaderone {
                position: relative;
                .portionheaderoneimg {
                    width: 100%;
                    height: 230px;
                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .portionheaderonetitle {
                    background: #be413f;
                    width: calc(100% - 40px);
                    padding: 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .portionheaderoneicon {
                        width: 19px;
                    }
                    .portionheaderonetxt {
                        font-size: 20px;
                        color: #ffffff;
                    }
                }
                .modeclass {
                    position: absolute;
                    top: 0;
                    background: rgba(0, 0, 0, 0.6);
                    width: 100%;
                    height: 230px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .modeicon {
                        width: 27px;
                    }
                    .modecontent {
                        color: #fff;
                    }
                }
            }
        }
        .portionfooter {
            width: 100%;
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 20px;
            .portionfooterone {
                position: relative;
                .portionfooteroneimg {
                    width: 100%;
                    height: 360px;
                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .portionfooteronetitle {
                    background: #eb6619;
                    width: calc(100% - 40px);
                    padding: 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .portionfooteroneicon {
                        width: 19px;
                    }
                    .portionfooteronetxt {
                        font-size: 20px;
                        color: #ffffff;
                    }
                }
                .modeclass {
                    position: absolute;
                    top: 0;
                    background: rgba(0, 0, 0, 0.6);
                    width: 100%;
                    height: 360px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .modeicon {
                        width: 27px;
                    }
                    .modecontent {
                        color: #fff;
                    }
                }
            }
        }
    }
    .room {
        width: 80vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        .roomone {
            display: flex;
            justify-content: space-around;
            padding-right: 20px;
            background: #fff9f9;
            border: 1px solid #ffe1e2;
            .roomoneimg {
                width: 300px;
                height: 300px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .roomonetitle {
                width: 20px;
                font-size: 20px;
                align-self: center;
                color: #000000;
                margin-left: 30px;
            }
        }
    }
    .roomvideo{
        width: 80vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .roomvideoone {
            border: 1px solid #ffe1e2;
            padding: 20px;
            .roomvideotitle {
                font-size: 20px;
                font-weight: bold;
                color: #b21e23;
                text-align: center;
            }
            .roomvideodes {
                font-size: 20px;
                margin: 20px 0;
                height: 52px;
            }
            .roomvideoimg {
                width: 100%;
            }
        }
    }
    .base {
        width: 60vw;
        margin: 30px auto;
    }
    .basebox {
        width: 80vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        .baseone {
            display: flex;
            padding-right: 20px;
            background: #fff9f9;
            border: 1px solid #ffe1e2;
            .baseimg {
                width: 370px;
                height: 300px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .basetitle {
                width: 20px;
                font-size: 20px;
                align-self: center;
                color: #000000;
                margin-left: 30px;
            }
        }
    }
    .neng{
         width: 80vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        align-items: center;
        .nengone1{
            width: 85%;
        }
    }
}
</style>